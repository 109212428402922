import { ConfigurableProduct, Maybe, PriceRange } from '@magentoTypes'

export const getCioVariationId = (
  product: ConfigurableProduct,
  variantIndex: number = 0,
): string | undefined => {
  if (
    product?.sku &&
    product?.variants?.length &&
    product?.variants?.[variantIndex]?.product?.sku
  ) {
    return `${product?.variants?.[variantIndex]?.product?.sku}-c-${product?.sku}`
  }

  return undefined
}

export const getCioProductPrice = (
  priceRange: PriceRange | undefined,
): Maybe<number> | undefined => {
  const hasSalePrice =
    priceRange?.minimum_price?.final_price?.value !==
    priceRange?.minimum_price?.regular_price?.value

  if (hasSalePrice) {
    return priceRange?.minimum_price?.final_price.value
  } else {
    return priceRange?.minimum_price?.regular_price.value
  }
}

export const extractConfigurableProductIdFromSku = (sku?: string): string => {
  if (sku) {
    const parts = sku.split('-')
    if (parts.length === 2) {
      return sku
    } else if (parts.length > 2) {
      return parts.slice(0, 2).join('-')
    }
  }
  return ''
}

export const extractVariationIdFromSku = (sku?: string): string | undefined => {
  if (sku) {
    const parts = sku.split('-')
    if (parts.length > 2) {
      return `${sku}-c-${parts.slice(0, 2).join('-')}`
    }
  }
  return undefined
}
